import './App.css';
import React from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import 'bootstrap/dist/css/bootstrap.min.css';

class LoginForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {username:"",password:"",rememberme:false};

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.onLogin=this.props.onLogin
    }
  
    handleChange(event) {  
        console.log('time stamp change',event.target.name,event.target.value)  
        const stat=this.state;
        stat[event.target.name]=event.target.value
        this.setState(stat);  
    }          
    handleSubmit(event) {
      console.log("Submit: ",this.state)
      event.preventDefault();
      this.sendForm(this.state);
    }
    render() {
        return (
        <div className=" d-flex align-items-center justify-content-center">
        <Container className="h-25 w-25 p-3 m-3 border">
        <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="username">
            <Form.Label>Login</Form.Label>
            <Form.Control onChange={this.handleChange} name="user" type="text" placeholder="Enter user name" />
        </Form.Group>
        <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control onChange={this.handleChange}  name="password" type="password" placeholder="Password" />
        </Form.Group>
        <Form.Group controlId="rememberme">
            <Form.Check onChange={this.handleChange} name="rememberme" type="checkbox" label="Remember me" />
        </Form.Group>
        <Button variant="primary" type="submit">
            Submit
        </Button>
        </Form>
        </Container>
        </div>
        );
    }
    
    sendForm(data) {
        const purl = 'dev/json/login'; 
        fetch(purl,{
            method: 'POST', // or 'PUT'
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then(response => {
              if(!response.ok)
              {
                throw new Error('Login reject');
              }
              return response.json()})
          .then( data=> {
            console.log(data);
            this.onLogin(data);
//            this.setState({ counters: data });
          })
          .catch(error => 
            {
            this.onLogin();
            console.error('Login error: ',error);
            }
            );
      }
  
  }
  export default LoginForm
  