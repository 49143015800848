import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Bar } from 'react-chartjs-2';
import Table from 'react-bootstrap/Table'

import "react-datetime/css/react-datetime.css";
import 'bootstrap/dist/css/bootstrap.min.css';

class StatisticView extends React.Component {
  constructor(props) {
    super(props);    
  }
  render()
  {
    console.log('master_holes',this.props.statistic.master_holes)
    console.log('staff_holes',this.props.statistic.staff_holes)
    const master_colors=[]
    const staff_colors=[]
    this.props.statistic.x_labels.forEach(element => { 
      if (element%2)
      {

        master_colors.push('rgba(0, 232,0, 0.6)');
        staff_colors.push('rgba(0, 132,0, 0.6)')

      }
      else
      {
        master_colors.push('rgba(232,0,0, 0.6)');
        staff_colors.push('rgba(132,0,0, 0.6)')
      }
    });
    const data = {
      labels: this.props.statistic.x_labels,
      datasets: [
        {
          label: 'Master',
          data: this.props.statistic.master_holes,
          borderWidth: 1,
          backgroundColor: master_colors
        },
        {
          label: 'Staff',
          data: this.props.statistic.staff_holes,
          borderWidth: 1,
          backgroundColor:staff_colors
        }
      ],
    };

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    return (
    <Container  >
          <Row><Bar data={data} options={options} /></Row>
          <Row><Col>Master </Col><Col>Staff </Col> </Row>
          <Row><Col>reset at: {this.props.statistic.master_reset_date}</Col><Col>reset at: {this.props.statistic.staff_reset_date}</Col> </Row>
          <Row><Col>coin drop {this.props.statistic.master_coin_in}</Col><Col>coin drop {this.props.statistic.staff_coin_in}</Col> </Row>
          <Row><Col>coin payout {this.props.statistic.master_coin_payout}</Col><Col>coin payout {this.props.statistic.staff_coin_payout}</Col> </Row>
          <Row><Col>coin gain {this.props.statistic.master_coin_gain}</Col><Col>coin gain {this.props.statistic.staff_coin_gain}</Col> </Row>
    </Container>
    );
  }
}

class StatisticViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {serial_no:this.props.serial_no,select:false,deviceList:[],ts_from:moment(this.props.ts_from).format(),ts_to:moment(this.props.ts_to).format()};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }

    this.handleChange=this.handleChange.bind(this);
    this.fetchStatistic=this.fetchStatistic.bind(this);
    console.log(this.state.select);
    console.log(this.state.ts_from);
    console.log(this.state.ts_to);
  }

    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
      this.fetchStatistic();
  }
  render() {
      return (
      <div><h1>Device statistics view</h1>
      <Container fluid>
          <Row className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        <option value="" selected disabled hidden>Select serial no</option>
                        {this.state.deviceList.map((dev)=>(<option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>))}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
          </Row>
        </Container>        
        {this.state.statistic?<StatisticView statistic={this.state.statistic}/>:"Please load data first"}
      </div>

      );
  }

  componentDidMount() {
      // make fetch request
      this.getDeviceList();
  }

  fetchStatistic() {
      const stat=this.state
      console.log('Fetch money list ',stat)
      var params={}
      this.setState({loading:true})
      params={
        device_id: stat.serial_no,
        ts_from: stat.ts_from,
        ts_to: stat.ts_to
      }
      const purl = 'dev/json/statistic?'+ new URLSearchParams(params); 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ statistic: data });
          this.setState({loading:false})
        });
    }

  getDeviceList(group) {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ deviceList: data });
        });
    }

}

export default StatisticViewer;
