import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Bar } from 'react-chartjs-2';
import 'bootstrap/dist/css/bootstrap.min.css';

class HolesChart extends React.Component {
  render()
  {
    const colors=[]

    this.props.holes.forEach((e,i) => { 
      if (i%2)
      {

        colors.push('rgba(0, 232,0, 0.6)');

      }
      else
      {
        colors.push('rgba(232,0,0, 0.6)');
      }
    });

    const data = {
      labels: this.props.holes.map((x,i)=>(i+1)),
      datasets: [
        {
          label: 'Drops',
          data: this.props.holes,
          borderWidth: 1,
          backgroundColor: colors
        },
      ],
    };

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    return(
    <Bar data={data} options={options} />
    )
  }
}

class SesionsView extends React.Component {
  constructor(props) {
    super(props);    
  }
  render()
  {
    var taxeBase=0
    this.props.sesions?.forEach(element => {
      if (element.prize>0)
      {
      taxeBase+=element.prize
      }

    });

    return (
      <Container>
        <Row className=" bg-primary"><Col  xs={1} lg={1}>No:</Col><Col>Sesion start</Col><Col>Sesion end</Col><Col>Coins drop</Col><Col>Payout</Col><Col>Player gain</Col><Col>Holes</Col></Row>
        {this.props.sesions.map((sesion,idx)=>(<Row className={(idx%2)==0?"bg-light":""}><Col  xs={1} lg={1}>{ idx }</Col><Col>{sesion.startTime}</Col><Col>{sesion.endTime}</Col><Col>{sesion.drop}</Col><Col>{sesion.payout}</Col><Col>{sesion.prize} </Col><Col><HolesChart holes={sesion.holes}/></Col></Row>))}
        <Row className=" bg-danger"><Col>Taxe base: {taxeBase}</Col></Row>
      </Container>
    );
  }
}


class SesionsViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {sesions: [],serial_no:this.props.serial_no,select:false,deviceList:[],ts_from:moment(this.props.ts_from).format(),ts_to:moment(this.props.ts_to).format()};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }

    this.handleTimestampFromChange = this.handleTimestampFromChange.bind(this);
    this.handleTimestampToChange= this.handleTimestampToChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.fetchSesionsList=this.fetchSesionsList.bind(this);
    console.log(this.state.select);
    console.log(this.state.ts_from);
    console.log(this.state.ts_to);
  }

  handleTimestampFromChange(event) {  
        console.log('time stamp FROM change',event.format())  
        const stat=this.state;
        stat.ts_from=event.format()
        this.setState(stat);  
      }
  handleTimestampToChange(event) {  
        console.log('time stamp TO change',event.format())  
        const stat=this.state;
        stat.ts_to=event.format()
        this.setState(stat);  
    }
    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
  }

 
  render() {
      return (
      <div><h3>Device counters view</h3>
      <Container fluid>
          <Row xs={1} lg={4} className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        <option value="" selected disabled hidden>Select serial no</option>
                        {this.state.deviceList.map((dev)=>(<option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>))}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
              <Col>
                Choose start time
                 <Datetime initialValue={ moment(this.props.ts_from).format("L LT")} name="ts_from" onChange={this.handleTimestampFromChange}/>
              </Col>
              <Col>
                Choose end time 
              <Datetime  initialValue={moment(this.props.ts_to).format("L LT")} name="ts_to" onChange={this.handleTimestampToChange}/>
              </Col>
              <Col className="text-md-left">
                  <Button variant="primary"  onClick={this.fetchSesionsList}>{this.state.loading?<Spinner  animation="border" variant="primary"/>:"Load"}</Button>
              </Col>
          </Row>
        </Container>
        {this.state.sesions?<SesionsView sesions={this.state.sesions}/>:"Please load data first"}
      </div>

      );
  }

  componentDidMount() {
      // make fetch request
      this.getDeviceList();
  }

  fetchSesionsList() {
      const stat=this.state
      console.log('Fetch counters list ',stat)
      var params={}
      params={
        device_id: stat.serial_no,
        ts_from: stat.ts_from,
        ts_to: stat.ts_to
      }
      this.setState({loading:true})
      const purl = 'dev/json/sesions?'+ new URLSearchParams(params); 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ sesions: data });
          this.setState({loading:false})
        });
    }

  getDeviceList(group) {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ deviceList: data });
        });
    }

}

export default SesionsViewer;
