import './App.css';
import React from "react";
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import  Button  from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import'./side.css'
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';


/*
  class Groups(db.Model):
	id = db.Column(db.Integer, primary_key=True)
	id_owner=db.Column(db.Integer, db.ForeignKey('users.id'))
	groupname = db.Column(db.String(64), index=True, unique=True)
	description = db.Column(db.Text, index=True, unique=True)
	machines = db.relationship("Machine",secondary=group_machine_association_table)
	def __repr__(self):
		return '<Group {0} owner {1}>'.format(self.groupname,self.owner_id)    


*/

class GroupView extends React.Component{
    constructor(props) {
        super(props);
      }
    render() {
        return ( 
        <Container fluid >
            <Row ><Col>Group name:</Col><Col>{this.props.group.name}</Col></Row>
            <Row ><Col>Description:</Col><Col>{this.props.group.description}</Col></Row>
            {this.props.group?.machines?.map((dev)=>(<Row><Col>{dev}</Col></Row>))}
        </Container>
        );
      }  
}

 // And now we can use these

 class GroupEdit extends React.Component{
  constructor(props) {
      super(props);
      this.state={group:this.props.group};
      this.state={deviceList:this.props.avaliableMachines};
    }
  componentDidMount() {
    }

  fetchDeviceList() {
  fetch('dev/json/dev_list')
    .then(response => response.json())
    .then( data=> {
      console.log(data);
      this.setState({ deviceList: data });
    });
  }
  createGroup(values, { setSubmitting }) 
  {
    console.log('Create group', values)
    fetch('/dev/json/group',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
    .then(response => response.json())
    .then( data=> {
      console.log(data);
      if (data?.result==="OK")
      {
        setSubmitting(true);
      }
      else
      {
        setSubmitting(false);
      }
    });
    
  }

  updateGroup(values, { setSubmitting }) 
  {
    console.log('update group', values)
    fetch('/dev/json/group',{
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
    .then(response => response.json())
    .then( data=> {
      console.log(data);
      if (data?.result==="OK")
      {
        setSubmitting(true);
      }
      else
      {
        setSubmitting(false);
      }            
    });
    this.onGroupChange()
  }

  render() {

    const MyTextInput = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input>. We can use field meta to show an error
      // message if the field is invalid and it has been touched (i.e. visited)
      const [field, meta] = useField(props);
      return (
        <div>
          <label className="m-3" htmlFor={props.id || props.name}>{label}</label>
          <input className="text-input " {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };
   
   const MyCheckbox = ({ children, ...props }) => {
      // React treats radios and checkbox inputs differently other input types, select, and textarea.
      // Formik does this too! When you specify `type` to useField(), it will
      // return the correct bag of props for you -- a `checked` prop will be included
      // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
      const [field, meta] = useField({ ...props, type: 'checkbox' });
      return (
        <div>
          <label className="checkbox-input m-1">
            <input type="checkbox" {...field} {...props} />
            {children}
          </label>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };
   
   const MySelect = ({ label, ...props }) => {
      const [field, meta] = useField(props);
      return (
        <div>
          <label htmlFor={props.id || props.name}>{label}</label>
          <select {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };
    console.log('group_id',this.props.group?.id)
    return (
      <>
        
        {this.props.group?.id?(<h1>Edit group</h1>):(<h1>Create group</h1>)}
        <Formik
          initialValues={{
            group_id:this.props.group?.id,
            groupname: this.props.group?.name,
            description: this.props.group?.description,
            machines:this.props.group?.machines
          }}
          validationSchema={Yup.object({
            groupname: Yup.string()
              .max(15, 'Must be 15 characters or less')
              .required('Required'),
            description: Yup.string()
              .max(20, 'Must be 32 characters or less')
              .required('Required'), 
          })}
          onSubmit={this.props.group?.id?this.updateGroup:this.createGroup}
        >
          <Form>
            <Container fluid><Row>
            <Col>
            <MyTextInput
              label="Group name"
              name="groupname"
              type="text"
              placeholder="Group name"
            />
            <MyTextInput
              label="Description"
              name="description"
              type="text"
              placeholder="Short group description"
            /> 
            <button type="submit">Submit</button>

            </Col>
            <Col>
              <h3>Machines in group</h3>
              {this.props.avaliableMachines?.map((dev)=>(
              <MyCheckbox
                  name="machines"
                  value={dev.device_id}
                  key={dev.device_id}
                > {dev.device_id} </MyCheckbox>
                ))}
            </Col>
            </Row>
          </Container>
          </Form>
        </Formik>
      </>
    );
     }    
}

class GroupsManage extends React.Component{
  constructor(props) {
      super(props);
      this.setState({action:'view'});
      this.handleChange=this.handleChange.bind(this);
      this.fetchGroup=this.fetchGroup.bind(this);
      this.fetchGroupsList=this.fetchGroupsList.bind(this);
      this.editUpadeHandler=this.editUpadeHandler.bind(this)
    }
    
    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
      if(event.target.name=="group_id")
      {
//        this.fetchGroupsList();
        this.fetchGroup(event.target.value);
        this.setState({action:"view"});  
      }
  }

  componentDidMount() {
    this.fetchGroupsList()
    }

  editUpadeHandler(gid)
  {
    this.state.action='view';
    this.fetchGroup(gid);
  }
    fetchGroup(gid) {
      console.log('fetchGroup',gid)
      const groups=this.state.groupsList
      const data=groups.find(g => g.id==gid);
      this.setState({ group: data });
    }
    
  fetchGroupsList() {
  fetch('dev/json/group')
    .then(response => response.json())
    .then( data=> {
      console.log(data);
      this.setState({ groupsList: data });
    });
  }
  render()
  {
    return(
        <Container fluid>
          <Row>
            <Col className="d-flex align-items-start">
          <ButtonGroup vertical className="m-3">

              <Button  variant="primary" onClick={this.handleChange} name="action" value="create">Create</Button>              
              {this.state?.group?(
                <>
                <Button  variant="primary" onClick={this.handleChange} name="action" value="view">View</Button>
                <Button  variant="primary" onClick={this.handleChange} name="action" value="edit">Edit</Button>
                <Button  variant="primary" onClick={this.handleChange} name="action" value="delete">Delete</Button>
              </>
              ):("")}
              <select variant="primary" className="form-select m-2 primary" id="group_id"   onChange={this.handleChange} name="group_id">
                  <option value="" selected disabled hidden>Select group</option>
                  {this.state?.groupsList?.map((grp)=>(<option key={grp.id} value={grp.id} >{grp.name}</option>))}
              </select>

          </ButtonGroup>
        </Col>
        <Col className="col-md-9 d-flex justify-content-left">
          {this.state?.action=='create'?(<GroupEdit  avaliableMachines={this.props.avaliableMachines} />):("")}
          {(this.state?.action=='view')?(
            <div className="m-2 justify-content-left">
            {this.state?.group?(<GroupView group={this.state.group} />):("")}
            </div>
          ):("")}
          {this.state?.action=='edit'?(
            <div className="m-2">
            <GroupEdit  group ={this.state.group}  avaliableMachines={this.props.avaliableMachines} onGroupChange={this.fetchGroupsList} />
            </div>
          ):("")}
          </Col>
          </Row>
      </Container>

    );
  }
}
//          

export {GroupEdit , GroupView ,GroupsManage};