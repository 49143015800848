import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';

class LogEventViewer extends React.Component {
  constructor(props) {
    super(props);
  }
  drawLine(line,idx)
  {
//    const line=this.props.dataLine;
    const fields=line.split(" ");
//        const itemClass="list-group-item list-group-item  active"
    var itemClass="bg-primary ";
    const eventText=fields.slice(3).join(" ")
    if(fields[2]==="HAL")
    {
      itemClass="bg-primary ";
    }
    else if(fields[2]==="Robber")
    {
        itemClass="bg-success ";
    }
    else if(fields[2]==="Jackpot")
    {
        itemClass="bg-info ";
    }
    else if(fields[2]==="Level UP")
    {
        itemClass="bg-secondary ";
    }

    else if(fields[2]==="AON")
    {
        itemClass="bg-light ";
    }

    if (eventText.includes("Coin"))
    {
        itemClass+="text-warning ";
    }
    if (eventText.includes("Bill"))
    {
        itemClass+=" text-white ";
    }
    return (            
            
            <ListGroup.Item  className={"text-md-left "+itemClass}> 
                {fields[0] +"  "+ fields[1]} {fields[2]}  {eventText} 
            </ListGroup.Item>
    )
  }
  render()
  {
    return (
    <Container fluid>
    <ListGroup >            
          {this.props.logs.length>0?(this.props.logs.map(this.drawLine)):(<h1>No logs to show</h1>)}
    </ListGroup>
    </Container>
    );
  }
}


class LogViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {logs:[],serial_no:this.props.serial_no,select:false,deviceList:[],ts_from:moment(this.props.ts_from).format(),ts_to:moment(this.props.ts_to).format()};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }

    this.handleTimestampFromChange = this.handleTimestampFromChange.bind(this);
    this.handleTimestampToChange= this.handleTimestampToChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange=this.handleChange.bind(this)
    this.fetchLogList=this.fetchLogList.bind(this);
    console.log(this.state.select)
    console.log(this.state.ts_from)
    console.log(this.state.ts_to)
  }

  handleTimestampFromChange(event) {  
          console.log('time stamp FROM change',event.format())  
          const stat=this.state;
          stat.ts_from=event.format()
          this.setState(stat);  
      }
  handleTimestampToChange(event) {  
        console.log('time stamp TO change',event.format())  
        const stat=this.state;
        stat.ts_to=event.format()
        this.setState(stat);  
    }
  handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
  }

    handleSubmit(event) {
    alert('Nazwa grupy: ' + this.state.groupName+this.state.selList);
    event.preventDefault();
  }
 
  render() {

      return (
      <div><h1>Device logs view</h1>
      <Container fluid>
        <Row xs={1} lg={4} className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        <option value="" selected disabled hidden>Select serial no</option>
                        {this.state.deviceList.map((dev)=>(<option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>))}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
              <Col>
                Choose start time
                 <Datetime initialValue={ moment(this.props.ts_from).format("L LT")} name="ts_from" onChange={this.handleTimestampFromChange}/>
              </Col>
              <Col>
                Choose end time 
              <Datetime  initialValue={moment(this.props.ts_to).format("L LT")} name="ts_to" onChange={this.handleTimestampToChange}/>
              </Col>
              <Col className="text-md-left">
                  <Button variant="primary"  onClick={this.fetchLogList}>{this.state.loading?<Spinner  animation="border" variant="primary"/>:"Load"}</Button>
              </Col>
          </Row>
        </Container>
        <LogEventViewer logs={this.state.logs}/>
      </div>

      );
  }

  componentDidMount() {
      // make fetch request
      this.getDeviceList();
  }

  fetchLogList() {
      const stat=this.state
      console.log('Fetch log list ',stat)
      this.setState({loading:true})
      fetch('dev/json/logs?'+new URLSearchParams({
          device_id: stat.serial_no,
          ts_from: stat.ts_from,
          ts_to: stat.ts_to
      }))
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ logs: data });
          this.setState({loading:false})
        });
    }

  getDeviceList(group) {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ deviceList: data });
        });
    }

}

export default LogViewer;
