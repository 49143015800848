import './App.css';
import React from "react";
import 'moment/locale/pl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ListGroup from 'react-bootstrap/ListGroup'  
import 'bootstrap/dist/css/bootstrap.min.css';
import'./side.css'
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';





class BonusCreate extends React.Component{
    constructor(props) {
        super(props);
        this.state={bonus:this.props.bonus};
        this.createBonus=this.createBonus.bind(this)
      }
    componentDidMount() {
      }
  
    createBonus(values, { setSubmitting }) 
    {
      console.log('Create bonus', values)
      let fdata = new FormData();

      fdata.append("val", values.val);
      fdata.append("life_time", values.life_time);
      fdata.append("freq", values.freq);
      fdata.append("desc", values.desc);
      fdata.append("count", values.count);
      fdata.append("icon", values.icon);
      fdata.append("ticket", values.ticket);
    
      for(var pair of fdata.entries()) {
        console.log(pair[0]+ ', '+ pair[1]);
     }
      
      return fetch("/app/createBonus", {
        method: "post",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " ,
        }),
        body: fdata,
      })
        .then((response) => response.json())
        .catch((error) => console.log(error));
      };
      
    render() {
  
      const MyTextInput = ({ label, ...props }) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input>. We can use field meta to show an error
        // message if the field is invalid and it has been touched (i.e. visited)
        const [field, meta] = useField(props);
        return (
          <div className=" row align-items-center">
            <span className=" text-md-right  col  "><label className="m-3 " htmlFor={props.id || props.name}>{label}</label></span>
            <span className=" text-md-left  col " ><input className="text-input" {...field} {...props} /></span>
            {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
            ) : null}
          </div>
        );
      };
     const bonus=this.props.bonus?this.props.bonus:{val: 10,life_time:30,freq:50,desc:"",count:1,icon:"",ticket:""}
      return (
        <>
          <Formik
            initialValues={{
              val: bonus.val,
              life_time: bonus.life_time,
              freq: bonus.freq,
              desc: bonus.desc,
              count:bonus.count,
              icon: bonus.icon,
              ticket: bonus.ticket,
            }}
            validationSchema={Yup.object({
                val: Yup.number().required('Required').min(1).max(1000),
                life_time: Yup.number().required('Required').positive().integer().min(1).max(600),
                freq: Yup.number().required('Required').positive().integer().min(1).max(1000),
                desc: Yup.string().max(32,'Max 32 characters'),
                count: Yup.number().required('Required').positive().integer().min(1).max(20),
            })}
            onSubmit={this.createBonus}
          >
            {(formProps) => (
            <Form>
              <Container>
              <MyTextInput
                label="Bonus prize"
                name="val"
                type="number"
                placeholder="1 - 200 coins"
              />
              <MyTextInput
                label="Lifetime"
                name="life_time"
                type="number"
                placeholder="Bonus life time 1 - 300 min"
              /> 
              <MyTextInput
                label="Show frequency"
                name="freq"
                type="number"
                placeholder="Apperance frequency 1 - 99%"
              /> 

              <MyTextInput
                label="Descryption"
                name="desc"
                type="text"
                placeholder="Short descryption"
              /> 
              <MyTextInput
                label="No of bonuses"
                name="count"
                type="number"
                placeholder="No fo bonuses to create"
              /> 
              <MyTextInput
                label="Icon file"
                name="iconf"
                type="file"
                onChange={(event) =>{
                    formProps.setFieldValue("icon", event.target.files[0]);
                  }}
              /> 
              <MyTextInput
                label="Ticket file"
                name="ticketf"
                type="file"
                onChange={(event) =>{
                    formProps.setFieldValue("ticket", event.target.files[0]);
                  }}
                /> 
              <Row className=" justify-content-center"><Button type="submit"  >Submit</Button> </Row>
              </Container> 
            </Form>
            )}
          </Formik>
        </>
      );
       }    
  }

  class BonusView extends React.Component {
    constructor(props) {
      super(props);      
    }
    render()    
    {
      return(     
          <Row>
          <Col className= {this.props.bonus.showStart?"text-danger":"text-primary"} ><div >Value: {this.props.bonus.value} 
          coins</div><div> Lifetime: {this.props.bonus.life_time} min</div><div> Frequency: {this.props.bonus.freq*2} rounds </div>
          <div> Start from: {this.props.bonus.showStart?'Begin':'End'}</div><div> Show: {this.props.bonus.noOfTakes} times</div> </Col> 
          { /*<Col ><Image src={`data:image/png;base64,${this.props.bonus.graphic.ticket}`} /></Col>*/ }
           <Col > { this.props.bonus.graphic? <Image src={`data:image/png;base64,${this.props.bonus.graphic.icon}`}/>:this.props.bonus.desc}</Col>  
          </Row>
      )
    }
  }

  class BonusSelectList extends React.Component {
    constructor(props) {
      super(props);  
      this.handleItemClick=this.handleItemClick.bind(this);
      this.state={selected:[]}
      
    }
    handleItemClick(idx) {        
      const sel=this.state.selected;
      const index = sel.indexOf(idx);
      if (index > -1) {
        sel.splice(index, 1);
      }
      else
      {
        if(sel.length<4)
        {
          sel.push(idx)
        }
      }
      this.setState({selected:sel})
      this.props.update_selected?.(sel)
    }
    render()
    {
      const bonusList=this.props.bonusList.sort((a,b)=>((a.value>b.value)?1:(b.value>a.value)?-1:(a.life_time>b.life_time)?1:(b.life_time>a.life_time)?-1:(a.freq>b.freq)?1:(b.freq>a.freq)?-1:0 ))
      return(            
        <ButtonGroup vertical  size='sm' justified>
        {bonusList.map((bonus)=>(        
          <Button  variant={this.state.selected.indexOf(bonus.id)>-1?"secondary":"outline-primary"} onClick={()=>this.handleItemClick(bonus.id)}>
            <BonusView  bonus={bonus} ></BonusView>          
          </Button>
        ))}
        </ButtonGroup>);
    }
  }

  class BonusSend extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {devices: [],bonuses:[],selected:[],selectedDevices:[],select:false}; 
      this.handleChange=this.handleChange.bind(this);
      this.fetchBonusList=this.fetchBonusList.bind(this);
      this.putBonus=this.putBonus.bind(this)
      this.update_selected=this.update_selected.bind(this);
      this.putMultiDeviceBonus=this.putMultiDeviceBonus.bind(this)
    }
  
      handleChange(event) {  
//        console.log('time stamp change',event.target.name,event.target.value)  
        const stat=this.state;
        var selectedDevices = []
        for(let i=0;i<event.target.selectedOptions.length;i++)
        {
          selectedDevices.push(event.target.selectedOptions.item(i).value)          
        }
//        console.log('Selected: ',selectedDevices)
        stat[event.target.name]=event.target.value
        stat['selectedDevices']=selectedDevices
//        console.log('On list change: ',stat)
        this.setState(stat);  
    }
    update_selected(sel)
    {
//      console.log(sel);
      this.setState({selected:sel});
    }
     
    render() {
        const resultText=this.props.putResult?'Bonus send correct':"";
        return (   
          <div>
            <Row xs={1} lg={1} className="align-items-center  pb-2 mb-2">
                <Col >                
                    <h3>Send Bonus</h3>
                    <label htmlFor="serial_no">Choose machines: </label>                 
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no" multiple>
                        <option key="-1" value="" selected disabled hidden>Select serial no</option>
                        {this.state.devices.map((dev)=>(<option key={dev.serial_no} value={dev.serial_no} >{dev.serial_no}</option>))}
                    </select>
                </Col>
                <Col>
                    <Button variant="primary"  onClick={this.putMultiDeviceBonus}>{this.state.loading?<Spinner  animation="border" variant="primary"/>:"Send to selected"}</Button>
                </Col>
            </Row>
            <Row xs={1} lg={1} className="align-items-center  pb-2 mb-2">
            <Col>
              <BonusSelectList bonusList={this.state.bonuses} update_selected={this.update_selected}></BonusSelectList>
            </Col>
            </Row>
{/*
            <Row xs={1} lg={1} className="align-items-center  pb-2 mb-2">
                <Col>
                    <Button variant="primary"  onClick={this.putBonus}>{this.state.loading?<Spinner  animation="border" variant="primary"/>:"Send"}</Button>
                </Col>
            </Row>
*/}
            </div>     
        );
    }
/*
                <Col >                
                    <label htmlFor="bonus_id">Choose  bonus: </label>
                    <select className="form-select" id="bonus_id" style={{ width:"auto" }}  onChange={this.handleChange} name="bonus_id">
                        <option key="-1" value="" selected disabled hidden>Select Bonus</option>
                        {this.state.bonuses.map((bonus)=>(<option key={bonus.id} value={bonus.id} >{bonus.desc}</option>))}
                    </select>
                </Col>

*/
    componentDidMount() {
        // make fetch request
        this.fetchBonusList();
    }
  
    fetchBonusList() {
//        console.log('Get bonus list ')
        this.setState({loading:true})
        const purl = 'dev/json/bonus'; 
        fetch(purl)
          .then(response => response.json())
          .then( data=> {
//            console.log(data);
            this.setState({ devices: data.devices.sort((a,b)=>(a.serial_no.substr(a.serial_no.length - 6)>b.serial_no.substr(b.serial_no.length - 6)?1:b.serial_no.substr(b.serial_no.length - 6)>a.serial_no.substr(a.serial_no.length - 6)?-1:0)) }); 
            this.setState({ bonuses: data.bonuses });
            this.setState({loading:false})
          });
      }  

      putMultiDeviceBonus() {
//        const stat=this.state
//        console.log('Put bonus to device ',stat)
        this.setState({loading:true})
        this.state.selectedDevices?.forEach(devSerialNo => 
        {
            this.state.selected?.forEach(element => {
              const outData={serial_no:devSerialNo,bonus_id:element};
//              console.log(outData);
              fetch('dev/json/bonus',{
                method: 'PUT',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(outData)
              })
              .then(response => response.json())
              .then( data=> {
      //          console.log(data);
                if (data?.result==="OK")
                {this.setState({putResult:true})}
                else
                {this.setState({putResult:false})}
              });    
            });
           
          });  
        this.setState({loading:false})
      }  
    
      putBonus() {
        const stat=this.state
//        console.log('Put bonus to device ',stat)
        this.setState({loading:true})
        this.state.selected?.forEach(element => {
//          console.log(element)
          fetch('dev/json/bonus',{
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({serial_no:stat.serial_no,bonus_id:element})
          })
          .then(response => response.json())
          .then( data=> {
  //          console.log(data);
            if (data?.result==="OK")
            {this.setState({putResult:true})}
            else
            {this.setState({putResult:false})}
          });
            
        });  
        this.setState({loading:false})
      }  
    }
  
    class BonusManage extends React.Component {
      constructor(props) {
        super(props);
        
        this.state = {devices: [],bonuses:[],select:false,action:'send'}; 
        this.handleChange=this.handleChange.bind(this);
      }
        handleChange(event) {  
          console.log('time stamp change',event.target.name,event.target.value)  
          const stat=this.state;
          stat[event.target.name]=event.target.value
          this.setState(stat);  
      }
      render() {
          return (
            <Container fluid>
            <Row>
              <Col className="d-flex align-items-start" size="lg">
                  <ButtonGroup vertical className='m-3'>
                  <Button  variant="primary"  onClick={this.handleChange} name="action" value="send">Send bonus form</Button>
                  <Button  variant="primary"  onClick={this.handleChange} name="action" value="create">Create bonus form</Button>                                
                  </ButtonGroup>
              </Col>
              <Col className="col-10  align-items-center">
                {this.state?.action=='create'?(<BonusCreate/>):("")}
                {this.state?.action=='send'?(<BonusSend putResult={this.state.putResult} />):("")}
              </Col>
            </Row>
        </Container>
      
          );
      }
    
      componentDidMount() {
          // make fetch request
      }
    }
          
  
  export {BonusManage,BonusSend,BonusCreate};