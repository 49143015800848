import './App.css';
import React from "react";
import 'moment/locale/pl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

//import "react-datetime/css/react-datetime.css";
//import 'bootstrap/dist/css/bootstrap.min.css';

class DevInfoView extends React.Component {
  constructor(props) {
    super(props);    
    this.render=this.render.bind(this);
  }

  render()
  {
    const keys=Object.entries(this.props.info);
    return (
    <Container  >
      <Row><Col>Serial no: </Col><Col>{this.props.info.SERIAL_NO}</Col></Row>
      <Row><Col>Admin password: </Col><Col>{this.props.info.ADMIN_PASSWORD}</Col></Row>
      <Row><Col>Master password: </Col><Col>{this.props.info.MASTER_PASSWORD}</Col></Row>
      <Row><Col>HW version: </Col><Col>{this.props.info.HW_VERSION}</Col></Row>
      <Row><Col>Device ID: </Col><Col>{this.props.info.DEVICE_ID}</Col></Row>
      <Row><Col>CPU ID: </Col><Col>{this.props.info.RPI_CPUID}</Col></Row>
      <Row><Col>Current IP: </Col><Col>{this.props.ip}</Col></Row>
    </Container>
    );
  }
}

class DevInfoViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {};
    this.state = {serial_no:this.props.serial_no,select:false,deviceList:[]};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }

    this.handleChange=this.handleChange.bind(this);

    this.fetchDevInfo=this.fetchDevInfo.bind(this);
    this.fetchVpnList=this.fetchVpnList.bind(this);
    this.getDeviceList=this.getDeviceList.bind(this);
    this.handleCheckBoxChange=this.handleCheckBoxChange.bind(this);
    this.checkIfOnVpn=this.checkIfOnVpn.bind(this)
  }

  render() {
      return (
      <div>
      <Container fluid>
        <Row xs={1}  className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        {this.state.deviceList.map((dev)=>{
                          if(this.state.avaliableFilter)
                          {
                            if(this.checkIfOnVpn(dev.device_id))
                            {
                            return <option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>
                            }
                          }
                          else
                          {
                            return <option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>
                          }
                        })}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
              <Col>
              <label for="avaliableFilter"> Only connected </label>
              <input type="checkbox" id="avaliableFilter" name="avaliableFilter" onChange={this.handleCheckBoxChange} className="ml-10"></input>
              </Col>
          </Row>
        </Container>        
        {this.state.info?<DevInfoView info={this.state.info} ip={this.state.ip}/>:"Please load data first"}
      </div>

      );
  }

  handleChange(event) {  
    console.log('time stamp change',event.target.name,event.target.value)  
    const stat=this.state;
    stat[event.target.name]=event.target.value
    this.setState(stat);  
    this.fetchDevInfo();
}

  handleCheckBoxChange(event) {  
    const stat=this.state;
    stat[event.target.name]=event.target.checked
    this.setState(stat);  

  }

  componentDidMount() {
      // make fetch request
      this.getDeviceList();
      this.fetchVpnList();
  }

  checkIfOnVpn(serial_no)
  {
    if(this.state.vpn)
    {
      for (const [key, value] of Object.entries(this.state.vpn)) {
        if(key.includes(serial_no)){
          return true
          }
        }
      }
    return false;
  }

  getVpnInfo(serial_no)
  {
    if(this.state.vpn)
    {
      for (const [key, value] of Object.entries(this.state.vpn)) {
        if(key.includes(serial_no)){
          return this.setState({ip:value[0]})
          }
        }
      }
    return undefined;
  }

  fetchDevInfo() {
      const stat=this.state
      console.log('Fetch VPN list ',stat)
      var params={}
      this.setState({loading:true})
      params={
        device_id: stat.serial_no
      }
      this.setState({loading:true})
      const purl = 'dev/json/info?' + new URLSearchParams(params); 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ info: data });
          this.setState({loading:false})
          for (const [key, value] of Object.entries(this.state.vpn)) {
            console.log(key,data.SERIAL_NO, value);
            if(key.includes(data.SERIAL_NO))
            {
              this.setState({ip:value[0][0]})
              console.log('Found ',key,value[0],this.state.ip)
            }
          }
          
        });
    }

  fetchVpnList() {
    const stat=this.state
    console.log('Fetch VPN list ',stat)
    var params={}
    this.setState({loading:true})
    const purl = 'dev/json/vpn' 
    fetch(purl)
      .then(response => response.json())
      .then( data=> {
          console.log(data);
        this.setState({ vpn: data });
        this.setState({loading:false})
      });
  }

  getDeviceList(group) {
    fetch('dev/json/dev_list')
      .then(response => response.json())
      .then( data=> {
        console.log(data);
        this.setState({ deviceList: data });
      });
  }

}

export default DevInfoViewer;
