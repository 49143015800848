import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Bar } from 'react-chartjs-2';
import Table from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css';


class IncomeView extends React.Component {
  constructor(props) {
    super(props);    
    this.state={sotrFunc:this.compareSno,filterValue:0}
    this.handleValueChange = this.handleValueChange.bind(this);
    this.filtredData=null
  }
  handleValueChange(event)
  {
    let value=0
    if(event.target.value)
    {
      value=parseInt((event.target.validity.valid)?event.target.value:this.state.filterValue)
      value=(value>10000)?10000:value
      value=(value<0)?0:value
    }
    this.setState({
      [event.target.name]: value
    });
  }

  getMachineRank(drops,fromTime)
  {
    if((fromTime==null)||(drops<0)){
      return "bg-danger"
    }
    if(drops==0)
    {
      return "text-danger"
    }
    else if(drops<100)
    {
      return "text-warning"
    }
    else if(drops>10000)
    {
      return "text-success"
    }
    else if(drops>1000)
    {
      return "text-primary"
    }
  }

  compareDrop(a, b) {
    if (a.drop>b.drop)
       return -1
    if (a.drop<b.drop)
       return 1
    // a równe b
    return 0
 }

 comparePayout(a, b) {
  if (a.payout>b.payout)
     return -1
  if (a.payout<b.payout)
     return 1
  // a równe b
  return 0
}

 compareSno(a, b) {
  if (a.sno<b.sno)
     return -1
  if (a.sno>b.sno)
     return 1
  // a równe b
  return 0
}

compareGain(a, b) {
  if (a.gain>b.gain)
     return -1
  if (a.gain<b.gain)
     return 1
  // a równe b
  return 0
}

  render()
  {
    var sumDrop=0,sumPayout=0,sumGain=0;

    const resultsList=[]
    for( const item in this.props.incomeList)
    {
      if(this.state.filterValue==0)
      {
        if((this.props.incomeList[item].time_from!=null)&&(this.props.incomeList[item].drop>=0)){
          sumDrop+=this.props.incomeList[item].drop;
          sumPayout+=this.props.incomeList[item].payout;
          sumGain+=this.props.incomeList[item].gain
          }
        resultsList.push({sno:item,drop:this.props.incomeList[item].drop,payout:this.props.incomeList[item].payout,gain:this.props.incomeList[item].gain,time_to:this.props.incomeList[item].time_to,time_from:this.props.incomeList[item].time_from})

      }
      else
      {
        if(this.props.incomeList[item].drop>=this.state.filterValue)
        {
          if((this.props.incomeList[item].time_from!=null)&&(this.props.incomeList[item].drop>=0)){
            sumDrop+=this.props.incomeList[item].drop;
            sumPayout+=this.props.incomeList[item].payout;
            sumGain+=this.props.incomeList[item].gain
            }
          resultsList.push({sno:item,drop:this.props.incomeList[item].drop,payout:this.props.incomeList[item].payout,gain:this.props.incomeList[item].gain,time_to:this.props.incomeList[item].time_to,time_from:this.props.incomeList[item].time_from})
        }

      }
      
    }
    resultsList.sort(this.state.sotrFunc)
    return (
    <Container  >
      <Row className="bg-primary " ><Col> No. </Col><Col><Button onClick={()=>(this.setState({sotrFunc:this.compareSno}))}> SerialNo </Button></Col><Col><Button onClick={()=>(this.setState({sotrFunc:this.compareDrop}))}> Drop >=  <input type="text" pattern="^[0-9]*$" name="filterValue"  onInput={this.handleValueChange} value={this.state.filterValue} className="FilterTextInput"/> </Button></Col><Col><Button onClick={()=>(this.setState({sotrFunc:this.comparePayout}))}> Payout </Button></Col><Col><Button onClick={()=>(this.setState({sotrFunc:this.compareGain}))}> Gain </Button></Col><Col > Gain in %</Col></Row>
      {resultsList.map((cnt,idx)=>(<Row className={this.getMachineRank(cnt.drop,cnt.time_from)} ><Col>{idx+1}</Col> <Col className="text-md-left ">  {cnt.sno} </Col><Col> {cnt.drop} </Col><Col> {cnt.payout} </Col><Col> {cnt.gain}</Col><Col> {cnt.drop!==0?(cnt.gain/cnt.drop*100).toFixed(1):0}%</Col></Row>))}
      <Row className="bg-secondary " ><Col></Col><Col> Sum:  </Col><Col> {sumDrop}  </Col><Col> {sumPayout}  </Col><Col> {sumGain} </Col><Col> {sumDrop!==0?(sumGain/sumDrop*100)?.toFixed(1):0}%</Col></Row>
      <Row className="bg-secondary " ><Col></Col><Col> AVG per Device:  </Col><Col> {resultsList.length?(sumDrop/resultsList.length).toFixed(1):"---"} </Col><Col> {resultsList.length>0?(sumPayout/resultsList.length).toFixed(1):"---"} </Col><Col> {resultsList.length>0?(sumGain/resultsList.length).toFixed(1):"---"}</Col><Col> </Col></Row>
    </Container>
    );

  }
}


class IncomeViewer extends React.Component {
  constructor(props) {
    super(props);

    this.Timeout = (time) => {
      let controller = new AbortController();
      setTimeout(() => controller.abort(), time * 1000);
      return controller;
    };
  
    this.state = {incomeList:[],ts_from:moment(this.props.ts_from).format(),ts_to:moment(this.props.ts_to).format(),cnt_set:"staff"};

    this.handleTimestampFromChange = this.handleTimestampFromChange.bind(this);
    this.handleTimestampToChange= this.handleTimestampToChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.fetchIncomeList=this.fetchIncomeList.bind(this);
    this.fetchGroupsList=this.fetchGroupsList.bind(this);
    console.log(this.state.select);
    console.log(this.state.ts_from);
    console.log(this.state.ts_to);
  }

  fetchGroupsList() {
    fetch('dev/json/group')
      .then(response => response.json())
      .then( data=> {
        console.log(data);
        this.setState({ groupsList: data });
      });
    }


  handleTimestampFromChange(event) {  
      try{
          console.log('time stamp FROM change',event.format())  
          const stat=this.state;
          stat.ts_from=event.format()
          this.setState(stat);  
      }
      catch
      {
        this.setState({ts_from:""});  
      }
      }
  handleTimestampToChange(event) {  
        console.log('time stamp TO change',event.format())  
        const stat=this.state;
        stat.ts_to=event.format()
        this.setState(stat);  
    }
    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
  }
  render() {
      return (
      <div><h1>Device income view</h1>
      <Container fluid>
        <Row xs={1} lg={6} className="align-items-center border pb-2 mb-2">
              <Col >
                Choose start time
                 <Datetime initialValue={ moment(this.props.ts_from).format("L")} name="ts_from" onChange={this.handleTimestampFromChange}/>
              </Col>
              <Col>
                Choose end time 
              <Datetime  initialValue={moment(this.props.ts_to).format("L")} name="ts_to" onChange={this.handleTimestampToChange}/>
              </Col>
              <Col >                
                <label for="serial_no">Counters set: </label><br></br>                  
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="cnt_set">
                      <option key="3" value="staff" >Staff</option>
                      <option key="2" value="service" >Service</option>
                      {(this.props.user?.id<3)||(this.props.user?.id==27)?<option key="1" value="master" >Master</option>:""}                        
                    </select>
              </Col>
              <Col >
                <label for="group_id">Group: </label><br></br>                  
                  <select className="form-select" id="group_id" style={{ width:"auto" }}  onChange={this.handleChange} name="group_id">
                    <option value="" selected disabled hidden>Select group</option>
                    {this.state.groupsList?.map((grp)=>(<option key={grp.name} value={grp.id} >{grp.name}</option>))}
                  </select>

              </Col>

              <Col className="text-md-center ">
                  <Button variant="primary"  onClick={this.fetchIncomeList}> {this.state.loading?<Spinner  animation="border" variant="primary"/>:"Load"} </Button>
                  </Col>
          </Row>
        </Container>        
        {this.state.incomeList?<IncomeView incomeList={this.state.incomeList}/>:"Please load data first"}
      </div>

      );
  }
//                        <option key="1" value="master" >Master</option>

  componentDidMount() {
      // make fetch request
//      this.getDeviceList();
    this.fetchGroupsList();
  }
  

  fetchIncomeList() {
      const stat=this.state
      console.log('Fetch income list ',stat)
      var params={}
      this.setState({loading:true})
      if (stat.group_id)
      {
        params={
          group_id: stat.group_id,
          ts_from: stat.ts_from,
          ts_to: stat.ts_to,
          cnt_set: stat.cnt_set
        }
        }
      else
      {
        params={
          ts_from: stat.ts_from,
          ts_to: stat.ts_to,
          cnt_set: stat.cnt_set
        }
      }
      console.log('params', params)
      const purl = '/dev/json/dev_list_income?'+ new URLSearchParams(params); 
      fetch(purl)
        .then(response =>{const json=response.json(); return json})  
        .then( data=> {
          this.setState({ incomeList: data });
          this.setState({loading:false})
        }).catch((r)=>{console.log("Fetch Error",r.blob());this.setState({loading:false})});
    }
}

export default IncomeViewer;
