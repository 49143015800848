import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

console.log('begin');

  class DeviceListItemShort extends React.Component {
    constructor(props) {
      super(props);
    }

    render()
    {
        if(this.props.device.device_id.length===12)
        {
          return (
          <div className=" container list-group-item  text-primary" key={this.props.device.device_id}> 
            <div className="row">
              <div className="col-sm"  >
                <div>{this.props.device.device_id} </div>
                <div>Last seen: {this.props.device.last_seen} </div>
                {this.props.device.active?(<div className="badge bg-success">Active</div>):("")}
              </div>
              <div className="col-sm">
                Reset date: {this.props.device.counters.reset}<br></br>
                Coins drop: {this.props.device.counters.drop} <br></br> 
                Coins payout: {this.props.device.counters.payout}<br></br>
                Gain:  {this.props.device.counters.gain}<br></br>
              </div>
              <div className="col-sm">
                Hopper level: {this.props.device.hpLvl}<br></br>
                Error: {this.props.device.errorNo} <br></br> 
              </div>
            </div>
          </div>
          );
        }
        else
        {
          return (
          <li className="list-group-item text-warning"  key={this.props.device.device_id}> {this.props.device.device_id} </li>
          );
        }
    }
  }
  
  class DeviceListShort extends React.Component {
    
    constructor(props) {
      super(props);
      this.handleChange=this.handleChange.bind(this);
      this.fetchGroupsList=this.fetchGroupsList.bind(this);

      this.state = {
        deviceList: [],
        date:new Date(),
      };
    }
  
    componentDidMount() {
        // make fetch request
        this.timerId=setInterval(()=>this.fetchDeviceList(),20000);
        this.fetchDeviceList();
        this.fetchGroupsList();
    }

    fetchDeviceList() {
      const stat=this.state
      var params
      stat.group_id?
      params={
        group_id: stat.group_id
      }:params={};
      fetch('dev/json/dev_list_info?'+ new URLSearchParams(params))
        .then(response => response.json())
        .then( data=> {
//          var cnt=this.state.cnt
          console.log(data);
          this.setState({ deviceList: data });
          this.setState({ date:new Date() });
        });
    }
    fetchGroupsList() {
      fetch('dev/json/group')
        .then(response => response.json())
        .then( data=> {
          console.log('fetch group',data);
          this.setState({ groupsList: data });
        });
      }
    handleChange(event) {  
      console.log('change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
    }
  
    componentWillUnmount() {
        clearInterval(this.timerId);
    }
    
    render() {

        var sum_drop=0,sum_payout=0;
        
        if (this.state.deviceList)
        {
          
          for( const tmp_dev in this.state.deviceList)
          {
            console.log('dev in list',tmp_dev)
            if (this.state.deviceList[tmp_dev].counters)
            {
            sum_drop+=this.state.deviceList[tmp_dev].counters.drop;
            sum_payout+=this.state.deviceList[tmp_dev].counters.payout;
            }
          }
        }
        const sum_gain=sum_drop-sum_payout;
        return (
          <div className="container-sm ">
            <div className="row ">
              <span className="col ">Refresh at: {this.state.date.toLocaleTimeString()}</span>
              {this.state.groupsList?.length>0?(
              <span className="col col-md-auto">
              <label for="group_id">Group: </label><br></br>                  
                <select className="form-select" id="group_id" style={{ width:"auto" }}  onChange={this.handleChange} name="group_id">
                  <option value="" selected disabled hidden>Select group</option>
                  {this.state.groupsList?.map((grp)=>(<option key={grp.name} value={grp.id} >{grp.name}</option>))}
                </select></span>):""}
              <button className="col" onClick={()=>this.fetchDeviceList()}>Refresh devices</button>
          </div>
          <div className="row border  bg-secondary">
            <div className="col">Drop: {sum_drop}</div><div className="col">Payout: {sum_payout}</div><div className="col">Gain: {sum_gain}</div> <div className="col">{sum_drop!=0?(sum_gain/sum_drop*100)?.toFixed(1):"--"}% </div>
          </div>
            <ul className="list-group dark">
                {this.state.deviceList.map((dev)=>(<DeviceListItemShort  device={dev} />))}
            </ul>
          </div>
        )
    }
}

export default  DeviceListShort;