import './App.css';
import React from "react";
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';


/*
	id = db.Column(db.Integer, primary_key=True)
	username = db.Column(db.String(64), index=True, unique=True)
	email = db.Column(db.String(120), index=True, unique=True)
	organization = db.Column(db.String(128))
	name= db.Column(db.String(128))
	surname = db.Column(db.String(128))
	phone = db.Column(db.String(16))
	
	id_parent = db.Column(db.Integer,db.ForeignKey('users.id'))
#	parent=db.relationship('Users',remote_side=[id])

#	init_group = db.Column(db.Integer,db.ForeignKey('groups.id'))
#	id_init_group = db.Column(db.Integer)

	id_group = db.Column(db.Integer)

#	groups = db.relationship('Groups')

#	access_rights=db.relationship('AccessRights',secondary=user_access_rigths_association_table)
#	acces_level = db.Column(db.Integer,db.ForeignKey('access_levels.id'))
	password_hash = db.Column(db.String(128))
	
	role_users_read=db.Column(db.Boolean)
	role_users_create=db.Column(db.Boolean)
	role_bonus_read=db.Column(db.Boolean)
	role_bonus_create=db.Column(db.Boolean)
	role_bonus_send=db.Column(db.Boolean)
	role_dev_config_read=db.Column(db.Boolean)
	role_dev_config_edit=db.Column(db.Boolean)
	role_dev_counters_read=db.Column(db.Boolean)
	role_dev_statistic_read=db.Column(db.Boolean)
	role_device_create=db.Column(db.Boolean)

*/

class UserInfoView extends React.Component{
    constructor(props) {
        super(props);
      }
    render() {
        return ( 
        <Container>
            <Row><Col>Login:</Col><Col>{this.props.user.login}</Col></Row>
            <Row><Col>Email:</Col><Col>{this.props.user.email}</Col></Row>
            <Row><Col>Organization:</Col><Col>{this.props.user.organization}</Col></Row>

            <Row><Col>Name:</Col><Col>{this.props.user.name}</Col></Row>
            <Row><Col>Surname:</Col><Col>{this.props.user.surname}</Col></Row>
            <Row><Col>Phone:</Col><Col>{this.props.user.phone}</Col></Row>
            <Row><Col>Machines group:</Col><Col>{this.props.user.id_group}</Col></Row>
        </Container>
        );
      }  
}

 


 
 // And now we can use these


 class UserInfoEdit extends React.Component{
  constructor(props) {
      super(props);
      this.state = {id:props.id,sel:false};
      this.handleChange = this.handleChange.bind(this);
    }
  handleChange(event) {   
       this.setState({sel: !this.state.sel});  
      }  
  render() {

    const MyTextInput = ({ label, ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input>. We can use field meta to show an error
      // message if the field is invalid and it has been touched (i.e. visited)
      const [field, meta] = useField(props);
      return (
        <div className='form-group'>
          <label className='FormInputLabel' htmlFor={props.id || props.name}>{label}</label>
          <input className="text-input FormTextInput" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };
   
   
   const MyCheckbox = ({ children, ...props }) => {
      // React treats radios and checkbox inputs differently other input types, select, and textarea.
      // Formik does this too! When you specify `type` to useField(), it will
      // return the correct bag of props for you -- a `checked` prop will be included
      // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
      const [field, meta] = useField({ ...props, type: 'checkbox' });
      return (
        <div >
          <label className="checkbox-input ">
            <input type="checkbox" {...field} {...props} />
            {children}
          </label>
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };
   const MySelect = ({ label, ...props }) => {
      const [field, meta] = useField(props);
      return (
        <div>
          <label htmlFor={props.id || props.name}>{label}</label>
          <select {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
          ) : null}
        </div>
      );
    };
   
    return (
      <>
        <h1>Create user</h1>
        <Formik
          initialValues={{
            login: '',
            name: '',
            surname:'',
            email: '',
            organization:'',
            phone: '', // added for our checkbox
            machines:this.props.machines,
          }}
          validationSchema={Yup.object({
            login: Yup.string()
              .max(15, 'Must be 15 characters or less')
              .required('Required'),
            name: Yup.string()
              .max(20, 'Must be 20 characters or less')
              .required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            surname: Yup.string()
              .max(20, 'Must be 20 characters or less'),
            organization: Yup.string()
              .max(20, 'Must be 20 characters or less'),
           phone: Yup.string()
              .max(20, 'Must be 20 characters or less'),
 
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          <Form>
          <Container><Row>
            <Col>
            <MyTextInput
              label="login"
              name="login"
              type="text"
              placeholder="Jane"
            />
            <MyTextInput
              label="Name"
              name="name"
              type="text"
              placeholder="John"
            />
            <MyTextInput
              label="Surname"
              name="surname"
              type="text"
              placeholder="Doe"
            />
            <MyTextInput
              label="Email Address"
              name="email"
              type="email"
              placeholder="jane@formik.com"
            />
 
            <MyTextInput
              label="Organization"
              name="organization"
              type="text"
              placeholder="Bank Robber LTD"
            />
 
            <MyTextInput
              label="Phone"
              name="phone"
              type="phone"
              placeholder="+48 ... ... .."
            />
            <button type="submit">Submit</button>
            </Col>
            <Col>
            <h3>Machines avaliable for user</h3>
            {this.props.avaliableMachines?.map((dev)=>(
            <MyCheckbox
                name="machines"
                value={dev.device_id}
              > {dev.device_id} </MyCheckbox>
              ))}
            </Col>
            </Row>
          </Container>
            
          </Form>
        </Formik>
      </>
    );
     }    
}

export {UserInfoEdit , UserInfoView};