import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import { Bar } from 'react-chartjs-2';
import Table from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css';


class MoneyView extends React.Component {
  constructor(props) {
    super(props);    
  }
  render()
  {
    var sumDrop=0,sumPayout=0,sumGain=0;
    const data = {
      labels: [],
      datasets: [
        {
          label: 'Drop',
          data: [],
          borderWidth: 1,
          backgroundColor: 'rgba(0, 0, 132, 0.6)'
        },
        {
          label: 'Payout',
          data: [],
          borderWidth: 1,
          backgroundColor: 'rgba(132, 0, 0, 0.6)'
        },
        {
          label: 'Gain',
          data: [],
          borderWidth: 1,
          backgroundColor: 'rgba(0, 132, 0, 0.6)',
        },

      ],
    };

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    this.props.counters.forEach((cnt)=>{
      data.labels.push(moment(cnt.date).format("L"))
      data.datasets[0].data.push(cnt.drop)
      data.datasets[1].data.push(cnt.payout)
      data.datasets[2].data.push(cnt.gain)
      sumDrop+=cnt.drop;
      sumPayout+=cnt.payout;
      sumGain+=cnt.gain});
  
    return (
    <Container  >
          <Row className="bg-primary " ><Col> Date </Col><Col> Drop </Col><Col> Payout </Col><Col > Gain </Col><Col > Gain in %</Col></Row>
          {this.props.counters.map((cnt)=>(<Row className="border" > <Col className="text-md-left ">  {moment(cnt.date).format("L")} </Col><Col> {cnt.drop} </Col><Col> {cnt.payout} </Col><Col> {cnt.gain}</Col><Col> {cnt.drop!==0?(cnt.gain/cnt.drop*100).toFixed(1):0}%</Col></Row>))}
          <Row className="bg-secondary " ><Col> Sum:  </Col><Col> {sumDrop} </Col><Col> {sumPayout} </Col><Col> {sumGain}</Col><Col> {sumDrop!==0?(sumGain/sumDrop*100)?.toFixed(1):0}%</Col></Row>
          <Row><Bar data={data} options={options} /></Row>
    </Container>
    );

/*
    return (
      <Container  >
        <table size="lg" className="table border">
            <thead  > <tr><th> Date </th><th> Drop </th><th> Payout </th><th colSpan="2"> Gain </th></tr></thead>
            <tbody>
            {this.props.counters.map((cnt)=>(<tr  > <th >  {moment(cnt.date).format("L")} </th><th> {cnt.drop} </th><th> {cnt.payout} </th><th > {cnt.gain}</th><th> {cnt.drop!==0?(cnt.gain/cnt.drop*100).toFixed(1):0}%</th></tr>))}
            <tr  ><th> Sum:  </th><th> {sumDrop} </th><th> {sumPayout} </th><th> {sumGain}</th><th> {sumDrop!==0?(sumGain/sumDrop*100)?.toFixed(1):0}%</th></tr>
            </tbody>
        </table>
        <Row><Bar data={data} options={options} /></Row>
      </Container>
      );
  */
  }
}


class MoneyViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {counters: [],serial_no:this.props.serial_no,select:false,deviceList:[],ts_from:moment(this.props.ts_from).format(),ts_to:moment(this.props.ts_to).format()};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }

    this.handleTimestampFromChange = this.handleTimestampFromChange.bind(this);
    this.handleTimestampToChange= this.handleTimestampToChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.fetchLogList=this.fetchLogList.bind(this);
    console.log(this.state.select);
    console.log(this.state.ts_from);
    console.log(this.state.ts_to);
  }

  handleTimestampFromChange(event) {  
      try{
          console.log('time stamp FROM change',event.format())  
          const stat=this.state;
          stat.ts_from=event.format()
          this.setState(stat);  
      }
      catch
      {
        this.setState({ts_from:""});  
      }
      }
  handleTimestampToChange(event) {  
        console.log('time stamp TO change',event.format())  
        const stat=this.state;
        stat.ts_to=event.format()
        this.setState(stat);  
    }
    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
  }
  render() {
      return (
      <div><h1>Device money view</h1>
      <Container fluid>
        <Row xs={1} lg={4} className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        <option value="" selected disabled hidden>Select serial no</option>
                        {this.state.deviceList.map((dev)=>(<option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>))}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
              <Col>
                Choose start time
                 <Datetime initialValue={ moment(this.props.ts_from).format("L")} name="ts_from" onChange={this.handleTimestampFromChange}/>
              </Col>
              <Col>
                Choose end time 
              <Datetime  initialValue={moment(this.props.ts_to).format("L")} name="ts_to" onChange={this.handleTimestampToChange}/>
              </Col>
              <Col className="text-md-center">
                  <Button variant="primary"  onClick={this.fetchLogList}> {this.state.loading?<Spinner  animation="border" variant="primary"/>:"Load"} </Button>
                  </Col>
          </Row>
        </Container>        
        {this.state.counters?.length?<MoneyView counters={this.state.counters}/>:"Please load data first"}
      </div>

      );
  }

  componentDidMount() {
      // make fetch request
      this.getDeviceList();
  }

  fetchLogList() {
      const stat=this.state
      console.log('Fetch money list ',stat)
      var params={}
      this.setState({loading:true})
      params={
        device_id: stat.serial_no,
        ts_from: stat.ts_from,
        ts_to: stat.ts_to
      }
      const purl = 'dev/json/money?'+ new URLSearchParams(params); 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ counters: data });
          this.setState({loading:false})
        });
    }

  getDeviceList(group) {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ deviceList: data });
        });
    }

}

export default MoneyViewer;
