import './App.css';
import React from "react";
import 'moment/locale/pl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

//import "react-datetime/css/react-datetime.css";
//import 'bootstrap/dist/css/bootstrap.min.css';

class VpnView extends React.Component {
  constructor(props) {
    super(props);    
    this.render=this.render.bind(this);
  }

  render()
  {
    console.log('render:');
    const keys=Object.entries(this.props.vpn);
    console.log('keys:', keys);
    return (
    <Container  >
      <Row><Col  xs={1} lg={1}>No</Col><Col xs={1} lg={1} >Nazwa</Col><Col> <Container  fluid><Row><Col>Ip </Col></Row></Container></Col></Row>
      {keys.map((key,idx)=>(<Row><Col  xs={1} lg={1}>{idx+1}</Col><Col xs={1} lg={1} > {key[0]}  </Col><Col><Container  fluid>{this.props?.vpn[key[0]]?.map((v)=>(<Row ><Col> {v[0]} </Col></Row>))}</Container></Col></Row> ))}
    </Container>
    );
  }
}
//      {keys.map((key)=>(<Row><Col xs={1} lg={1} > {key[0]}  </Col><Col><Container  fluid>{this.props?.vpn[key[0]]?.map((v)=>(<Row xs={2} lg={4}><Col> {v[0]} </Col> <Col> {v[3]} </Col><Col> {v[1]} </Col><Col> {v[2]} </Col></Row>))}</Container></Col></Row> ))}
class VpnViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {};
    this.fetchVpnList=this.fetchVpnList.bind(this);
  }

  render() {
      return (
      <div>
      <Container fluid>
          <Row className="align-items-center border pb-2 mb-2">
              <Col >                
              <Button variant="primary"  onClick={this.fetchVpnList}> {this.state.loading?<Spinner  animation="border" variant="primary"/>:"Load"} </Button>
              </Col>
          </Row>
        </Container>        
        {this.state.vpn?<VpnView vpn={this.state.vpn}/>:"Please load data first"}
      </div>

      );
  }

  componentDidMount() {
      // make fetch request
      this.fetchVpnList();
  }

  fetchVpnList() {
      const stat=this.state
      console.log('Fetch VPN list ',stat)
      var params={}
      this.setState({loading:true})
      const purl = 'dev/json/vpn' 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
//          console.log(data);
          this.setState({ vpn: data });
          this.setState({loading:false})
        });
    }


}

export default VpnViewer;
