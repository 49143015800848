import './App.css';
import React from "react";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
import 'moment/locale/pl';
import LogViewer from './LogViewer.js'
import DeviceListShort from './DevListShort.js'
import CountersViewer from './CounterViewer.js'
import LoginForm from './LoginForm.js'
import {UserInfoEdit , UserInfoView} from './User.js'
import MoneyViewer from './MoneyViewer.js'
import StatisticViewer from './StatisticViewer.js'
import VpnViewer from './vpnViewer.js'
import DevInfoViewer from './devInfoViewer.js'
import SesionsViewer from './SesionsView.js'
import StateViewer from './StateViewer.js'
import IncomeViewer from './IncomeViewer.js'
import {BonusManage,BonusSend,BonusCreate} from './Bonuses.js'

import {GroupsManage} from './Groups.js'
import { Button} from 'react-bootstrap';


import 'bootstrap/dist/css/bootstrap.min.css';
import PasswordChangeForm from './PasswordChange';

class MainApp extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {user:{},userLogged:false,role:""};

    const prev = new Date()
    const prevCnt = new Date()
    const today = new Date()

    this.todayDate=today.toLocaleDateString()
    console.log('today',this.todayDate)
    prev.setHours(prev.getHours() - 2);
    prevCnt.setDate(prevCnt.getDate()-7);
    this.onLogin =this.onLogin.bind(this);
    this.logOut=this.logOut.bind(this);
    }

    componentDidMount() {
      // make fetch request
      this.checkUser();
      this.fetchDeviceList();
      }

    fetchDeviceList() {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log('Main APP',data);
          this.setState({ deviceList: data });
        });
      }
      
      
    checkUser() {
      // make fetch request
      const purl = 'dev/json/login'; 
      fetch(purl,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(""),
        })
        .then(response => response.json())
        .then( data=> {
//          console.log(data);
          if(data.result==="OK")
          {
            this.setState({user:data.user,userLogged:true,role:data.role})
          }
          else
          {
            this.setState({user:{},role:"",userLogged:false})
          }
            });
      }
  
    onLogin(data)
    {
//      console.log('onLogin ',data);
      if(data?.result==="OK")
      {
        this.setState({user:data.user,userLogged:true,role:data.role})
      }
      else
      {
      this.setState({user:{},role:"",userLogged:false})
      }
      this.forceUpdate()
    }

    logOut() {
      // make fetch request
      const purl = 'dev/json/logout'; 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.onLogin();
            });
      this.forceUpdate()
      }

    render()
    {
//      console.log('render',this.state);
      if (this.state.userLogged===true)
      {
      return (
        <Router>
        <div className="App">
          <Navbar bg="dark" expand="lg" variant="dark ">
            <Navbar.Brand href="#home"><div>Bank robber { this.todayDate }</div><div>User: {this.state.user.login}</div></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="cabList">Devices</Nav.Link>
                <Nav.Link href="logs">Logs</Nav.Link>
                <Nav.Link href="counters">Counters</Nav.Link>
                <Nav.Link href="money">Money</Nav.Link>
                <Nav.Link href="sesions">Player sesions</Nav.Link>
                <Nav.Link href="statistics">Statistics</Nav.Link>
                <Nav.Link href="state">Game state</Nav.Link>
                <Nav.Link href="user">User info</Nav.Link>
                {(this.state.user.id<5)||(this.state.user.id==27)?(
                <Nav.Link href="incomeinfo">Device Income</Nav.Link>
                ):("")
                  }
                {this.state.user.id<5?(                     
                  <>           
                  <Nav.Link href="vpninfo">Vpn info</Nav.Link>
                  <Nav.Link href="bonuses">Bonuses</Nav.Link>
                  </>
                  ):("")}

                {this.state.user.id<=2?(                     
                  <>           
                  <Nav.Link href="groups">Groups</Nav.Link>
                  <Nav.Link href="cabinfo">Device info</Nav.Link>
                  </>
                  ):("")}
                {this.state.user.id===1?(                     
                  <>           
                  <Nav.Link href="users">User</Nav.Link>
                  <Nav.Link href="PassChange">Password Change</Nav.Link>
                  </>
                  ):("")}

                <Button onClick={this.logOut}>Logout</Button>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Switch>
            <Route path="/cabList">
              <DeviceListShort/>
            </Route>
            <Route path="/logs">
              <LogViewer ts_from={ this.prev} ts_to={this.now} />
            </Route>
            <Route path="/counters">
              <CountersViewer ts_from={ this.prevCnt} ts_to={this.now} />
            </Route>
            <Route path="/money">
              <MoneyViewer ts_from={ this.prevCnt} ts_to={this.now} />
            </Route>

            <Route path="/sesions">
              <SesionsViewer ts_from={ this.prevCnt} ts_to={this.now} />
            </Route>

            <Route path="/statistics">
              <StatisticViewer ts_from={ this.prevCnt} ts_to={this.now} />
            </Route>
            <Route path="/user">
              <UserInfoView user={this.state.user}></UserInfoView>
            </Route>
            <Route path="/login">
              <UserInfoView user={this.state.user}></UserInfoView>
            </Route>
            
            <Route path="/incomeinfo">
              <IncomeViewer user={this.state.user}/>
            </Route>            

            <Route path="/cabinfo">
              <DevInfoViewer/>
            </Route>            
            <Route path="/state">
              <StateViewer/>
            </Route>            
            <Route path="/vpninfo">
              <VpnViewer/>
            </Route>
            <Route path="/groups">
              <GroupsManage avaliableMachines={this.state.deviceList}/>
            </Route>
            <Route path="/users">
              <UserInfoEdit machines= {["012106000033","012106000034"]} avaliableMachines={this.state.deviceList}/>
            </Route>
            <Route path="/bonuses">
              <BonusManage/>
            </Route>

            <Route path="/PassChange">
              <PasswordChangeForm/>
            </Route>
            
            
          </Switch>
        </div>
        </Router> );
    }
    else
    {
      return (<LoginForm onLogin={this.onLogin}></LoginForm>)
    }
  }
  
}

function App() {
  return( <MainApp></MainApp>)

}

export default App;
