import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";

import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';

/*
Coins_DROP 
Coins_PAY_OUT 

Coins_OUT 
Coins_BILLS_IN 
Coins_HAND_PAY_OUT 

Doors_CABINET_OPEN 
Doors_CABINET_CLOSE

Doors_LOGIC_OPEN 
Doors_LOGIC_CLOSE

Doors_HOPPER_OPEN
Doors_HOPPER_CLOSE 

Doors_DROP_BOX_OPEN
Doors_DROP_BOX_CLOSE 

Bonus_FS_AMOUNT
Bonus_FS_WONS
Bonus_FS_PAYOUT

Bonus_ADD_AMOUNT
Bonus_ADD_WONS
Bonus_ADD_PAYOUT

Bonus_MONEY_BAG_AMOUNT
Bonus_MONEY_BAG_WONS
Bonus_MONEY_PAYOUT

Bonus_REAMACH_AMOUNT
Bonus_REAMACH_WONS
Bonus_REAMACH_PAYOUT

Bonus_COIN_STACK_WONS 
Bonus_COIN_STACK_PAYOUT

Bonus_LEVEL_UP_AMOUNT
Bonus_LEVEL_UP_WONS
Bonus_LEVEL_UP_PAYOUT

Bonus_NET_AMOUNT 
Bonus_NET_WONS 
Bonus_NET_PAYOUT 

Bonus_COPPER_AMOUNT 
Bonus_COPPER_WONS 
Bonus_COPPER_PAYOUT 

Bonus_SILVER_AMOUNT 
Bonus_SILVER_WONS 
Bonus_SILVER_PAYOUT 

Bonus_GOLD_AMOUNT 
Bonus_GOLD_WONS 
Bonus_GOLD_PAYOUT 

Bonus_JACKPOT_AMOUNT 
Bonus_JACKPOT_WONS 
Bonus_JACKPOT_PAYOUT 
*/
class CountersView extends React.Component {
  constructor(props) {
    super(props);
    
  }
  render()
  {
    const dict=[];
    for (const [key, value] of Object.entries(this.props.counters)) {
        dict.push({name:key,val:value})
      }
      dict.push({name:"Coins_GAIN",val:this.props.counters.Coins_DROP-this.props.counters.Coins_PAY_OUT})
    console.log(dict);      
    return (
    <Container  >
      <Row>
      <Col ><Container >      
          <Row className="bg-primary "><Col>Name</Col><Col> Value</Col></Row>
          <Row className="border"><Col className="text-md-left ">Bills in</Col><Col> {this.props.counters.Coins_BILLS_IN}</Col></Row>
          <Row className="border"><Col className="text-md-left ">Coins out</Col><Col> {this.props.counters.Coins_OUT}</Col></Row>
          <Row className="border"><Col className="text-md-left ">Coin hand payout</Col><Col> {this.props.counters.Coins_HAND_PAY_OUT }</Col></Row>        
          <Row className=" bg-secondary"><Col className="text-md-left ">Coins drop</Col><Col> {this.props.counters.Coins_DROP}</Col></Row>
          <Row className=" bg-danger" ><Col className="text-md-left ">Coins payout</Col><Col> {this.props.counters.Coins_PAY_OUT}</Col></Row>
          <Row className=" bg-success"><Col className="text-md-left ">Gain</Col><Col> {this.props.counters.Coins_DROP-this.props.counters.Coins_PAY_OUT}</Col></Row>
      </Container></Col>

        <Col sm={5}><Container className="border">
        <Row className="bg-primary "><Col> Name </Col><Col> Amount </Col><Col> Wons </Col><Col> Payout </Col></Row>
        <Row className="border"><Col className="text-md-left ">  FS </Col><Col> {this.props.counters.Bonus_FS_AMOUNT} </Col><Col> {this.props.counters.Bonus_FS_WONS} </Col><Col> {this.props.counters.Bonus_FS_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left ">  ADD </Col><Col> {this.props.counters.Bonus_ADD_AMOUNT} </Col><Col> {this.props.counters.Bonus_ADD_WONS} </Col><Col> {this.props.counters.Bonus_ADD_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left ">  MONEY BAG </Col><Col> {this.props.counters.Bonus_MONEY_BAG_AMOUNT} </Col><Col> {this.props.counters.Bonus_MONEY_BAG_WONS} </Col><Col> {this.props.counters.Bonus_MONEY_BAG_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left ">  REAMACH </Col><Col> {this.props.counters.Bonus_REAMACH_AMOUNT} </Col><Col> {this.props.counters.Bonus_REAMACH_WONS} </Col><Col> {this.props.counters.Bonus_REAMACH_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left ">  COIN STACK </Col><Col> --- </Col><Col> {this.props.counters.Bonus_COIN_STACK_WONS} </Col><Col> {this.props.counters.Bonus_COIN_STACK_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left ">  LEVEL UP </Col><Col> {this.props.counters.Bonus_LEVEL_UP_AMOUNT} </Col><Col> {this.props.counters.Bonus_LEVEL_UP_WONS} </Col><Col> {this.props.counters.Bonus_LEVEL_UP_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left "> NET </Col><Col> {this.props.counters.Bonus_NET_AMOUNT} </Col><Col> {this.props.counters.Bonus_NET_WONS} </Col><Col> {this.props.counters.Bonus_NET_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left "> COPPER </Col><Col> {this.props.counters.Bonus_COPPER_AMOUNT} </Col><Col> {this.props.counters.Bonus_COPPER_WONS} </Col><Col> {this.props.counters.Bonus_COPPER_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left "> SILVER </Col><Col> {this.props.counters.Bonus_SILVER_AMOUNT} </Col><Col> {this.props.counters.Bonus_SILVER_WONS} </Col><Col> {this.props.counters.Bonus_SILVER_PAYOUT} </Col></Row>
        <Row className="border"><Col className="text-md-left "> GOLD </Col><Col> {this.props.counters.Bonus_GOLD_AMOUNT} </Col><Col> {this.props.counters.Bonus_GOLD_WONS} </Col><Col> {this.props.counters.Bonus_GOLD_PAYOUT} </Col></Row>
      </Container></Col>

      
      <Col ><Container >      
          <Row className="bg-primary border"><Col className="text-md-left ">Name</Col><Col> Open </Col><Col> Close </Col></Row>
          <Row className="border"><Col className="text-md-left ">CABINET</Col><Col> {this.props.counters.Doors_CABINET_OPEN }</Col><Col> {this.props.counters.Doors_CABINET_CLOSE }</Col></Row>
          <Row className="border"><Col className="text-md-left ">LOGIC</Col><Col> {this.props.counters.Doors_LOGIC_OPEN }</Col><Col> {this.props.counters.Doors_LOGIC_CLOSE }</Col></Row>
          <Row className="border"><Col className="text-md-left ">HOPPER</Col><Col> {this.props.counters.Doors_HOPPER_OPEN }</Col><Col> {this.props.counters.Doors_HOPPER_CLOSE }</Col></Row>
          <Row className="border"><Col className="text-md-left ">DROP BOX</Col><Col> {this.props.counters.Doors_DROP_BOX_OPEN }</Col><Col> {this.props.counters.Doors_DROP_BOX_CLOSE }</Col></Row>
      </Container></Col>
      </Row>
    </Container>
    );
  }
}


class CountersViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {counters: {},serial_no:this.props.serial_no,select:false,deviceList:[],ts_from:moment(this.props.ts_from).format(),ts_to:moment(this.props.ts_to).format()};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }

    this.handleTimestampFromChange = this.handleTimestampFromChange.bind(this);
    this.handleTimestampToChange= this.handleTimestampToChange.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.fetchLogList=this.fetchLogList.bind(this);
    console.log(this.state.select);
    console.log(this.state.ts_from);
    console.log(this.state.ts_to);
  }

  handleTimestampFromChange(event) {  
      try{
          console.log('time stamp FROM change',event.format())  
          const stat=this.state;
          stat.ts_from=event.format()
          this.setState(stat);  
      }
      catch
      {
        this.setState({ts_from:""});  
      }
      }
  handleTimestampToChange(event) {  
        console.log('time stamp TO change',event.format())  
        const stat=this.state;
        stat.ts_to=event.format()
        this.setState(stat);  
    }
    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
  }

 
  render() {
      return (
      <div><h3>Device counters view</h3>
      <Container fluid>
          <Row xs={1} lg={4} className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        <option value="" selected disabled hidden>Select serial no</option>
                        {this.state.deviceList.map((dev)=>(<option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>))}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
              <Col>
                Choose start time
                 <Datetime initialValue={ moment(this.props.ts_from).format("L LT")} name="ts_from" onChange={this.handleTimestampFromChange}/>
              </Col>
              <Col>
                Choose end time 
              <Datetime  initialValue={moment(this.props.ts_to).format("L LT")} name="ts_to" onChange={this.handleTimestampToChange}/>
              </Col>
              <Col className="text-md-left">
                  <Button variant="primary"  onClick={this.fetchLogList}>{this.state.loading?<Spinner  animation="border" variant="primary"/>:"Load"}</Button>
              </Col>
          </Row>
        </Container>
        {this.state.counters.Coins_OUT?<CountersView counters={this.state.counters}/>:"Please load data first"}
      </div>

      );
  }

  componentDidMount() {
      // make fetch request
      this.getDeviceList();
  }

  fetchLogList() {
      const stat=this.state
      console.log('Fetch counters list ',stat)
      var params={}
      if(stat.ts_from==="")
      {
      params={
        device_id: stat.serial_no,
        ts_to: stat.ts_to
      }
    }
    else
    {
      params={
        device_id: stat.serial_no,
        ts_from: stat.ts_from,
        ts_to: stat.ts_to
      }
    }
      this.setState({loading:true})
      const purl = 'dev/json/counters?'+ new URLSearchParams(params); 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ counters: data });
          this.setState({loading:false})
        });
    }

  getDeviceList(group) {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ deviceList: data });
        });
    }

}

export default CountersViewer;
