import './App.css';
import React from "react";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import ProgressBar from 'react-bootstrap/ProgressBar'
import "react-datetime/css/react-datetime.css";
import 'bootstrap/dist/css/bootstrap.min.css';



class StateView extends React.Component {
  constructor(props) {
    super(props);    
  }
  render()
  { 
 
    const level_up=['SILVER','COPPER','JACKPOT','FS 5','FS 2']
    const bonuses=['','Monye Bag','+2','+3','+4','+5','+6','+7','+8','+9','+10','Level Up','FS 2','FS 5','Reamach','Net']
    const aon_levels=['Copper','Silver','Gold']
    if(this.props.state.state)
    {
      const state=this.props.state.state
  //    console.log(this.props.state)
//      console.log(state['ROBBER'].holes_status)
      switch(state?.GAME?.game)
      {
        case 0:
        case 1:
          return (
            <Container>
                <Row><Col>Status time: {this.props.state.timestamp} </Col></Row>
                <Row><Col>Game: ROBBER {state['ROBBER'].mode>0?"Reamach":""} </Col></Row>
                <Row><Col>Jackpot counter: {state['ROBBER'].JackPotCnt}</Col> </Row>
                <Row><Col>FreeShots: {state['ROBBER'].freeShots} </Col></Row>
                <Row><Col>Add bonus: {state['ROBBER'].multiplyBonus} </Col></Row>
                <Row><Col>Bonuses: {state.bonuses?.map((item)=>(item.expire>=0?<div>{item.desc}  Expire: {item.expire.toFixed(1)} s</div>:''))} </Col></Row>
                <Row className="bg-primary "><Col>Hole No</Col><Col>Coins</Col><Col>Bonus</Col></Row>
                {state['ROBBER'].holes_status.map((hole,idx)=>(<Row className={(idx%2)==0?"bg-light":""}><Col>{hole.no}</Col><Col>{(idx%2)==0?"":<ProgressBar className="progress" variant="warning"  now={hole.coins} max='10' label={hole.coins}/>} </Col><Col>{ bonuses[hole.bonus]}</Col></Row>))}
            </Container>
            );      
        case 2:
          return (
            <Container>
                <Row><Col>Status time: {this.props.state.timestamp} </Col></Row>
                <Row><Col>Game: AON  </Col></Row>
                <Row><Col>Level: {aon_levels[state['AON'].level[0]]}</Col> </Row>
                <Row><Col>Wining holes: {state['AON'].bonus_holes.map((x)=>(<span>{x},</span>))}</Col> </Row>
            </Container>
            );          
          break;
        case 3:
          return (
            <Container>
                <Row><Col>Status time: {this.props.state.timestamp} </Col></Row>
                <Row><Col>Game: JackPot  </Col></Row>
                <Row><Col>Wining holes: {state['JACKPOT'].win_hole}</Col> </Row>
            </Container>
            );      
    
        break;
        case 4:
          return(
            <Container>
              <Row><Col>Status time: {this.props.state.timestamp} </Col></Row>
              <Row><Col>Game: Level Up </Col></Row>
              <Row className="bg-primary "><Col>Hole No</Col><Col>Bonus</Col></Row>
              {state['LEVEL_UP'].holes.map((hole,idx)=>(<Row className={(idx%2)==0?"bg-light":""}><Col>{hole.hole_no}</Col><Col>{ level_up[hole.bonus]}</Col></Row>))}
            </Container>
          );
        break;
      }
    }

    return (
    <>
    </>
    );
  }
}

class StateViewer extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {serial_no:this.props.serial_no,select:false,deviceList:[]};
    if(this.props.serial_no === undefined)
    {
      this.state.select=true;
    }
    else if (this.props.serial_no === "")
    {
      this.state.select=true;
    }
    else 
    {
      this.state.select=false;
    }
    this.handleChange=this.handleChange.bind(this);
    this.fetchState=this.fetchState.bind(this);
    console.log(this.state.select);
  }

    handleChange(event) {  
      console.log('time stamp change',event.target.name,event.target.value)  
      const stat=this.state;
      stat[event.target.name]=event.target.value
      this.setState(stat);  
      this.fetchState();
  }
  render() {
      return (
      <div><h1>Device state view</h1>
      <Container fluid>
          <Row className="align-items-center border pb-2 mb-2">
              <Col >                
                  <label for="serial_no">Choose machine: </label><br></br>                  
                  {this.state.select?(
                    <select className="form-select" id="serial_no" style={{ width:"auto" }}  onChange={this.handleChange} name="serial_no">
                        <option value="" selected disabled hidden>Select serial no</option>
                        {this.state.deviceList.map((dev)=>(<option key={dev.device_id} value={dev.device_id} >{dev.device_id}</option>))}
                    </select>
                  ):(this.state.serial_no)}
              </Col>
              <Col>
              <Button variant="primary"  onClick={this.fetchState}>{this.state.loading?<Spinner  animation="border" variant="primary"/>:"Reload"}</Button>
              </Col>
          </Row>
        </Container>        
        {this.state.state?<StateView state={this.state.state}/>:"Please load data first"}
      </div>

      );
  }

  componentDidMount() {
      console.log('Game state mount')
      this.timerId=setInterval(()=>this.fetchState(),5000);
      this.getDeviceList();
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  fetchState() {
      const stat=this.state
      console.log('Fetch game state ',stat)
      var params={}
      this.setState({loading:true})
      params={
        device_id: stat.serial_no,
      }
      const purl = 'dev/json/gamestate?'+ new URLSearchParams(params); 
      fetch(purl)
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ state: data });
          this.setState({loading:false})
        });
    }

  getDeviceList(group) {
      fetch('dev/json/dev_list')
        .then(response => response.json())
        .then( data=> {
          console.log(data);
          this.setState({ deviceList: data });
        });
    }

}

export default StateViewer;
